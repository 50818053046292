import easyToggle from "easy-toggle-state";
import Swiper from "swiper/bundle";
import "inputmask/dist/jquery.inputmask";

const initScripts = () => {
	easyToggle();

	document.querySelectorAll(".form-select").forEach((select) => {
		const choices = new Choices(select, {
			searchEnabled: false,
			placeholderValue: null,
			itemSelectText: ""
		});
	});

	$("input[data-mask]").each(function() {
		var mask;
		mask = $(this).attr("data-mask");
		if (mask) {
			$(this).inputmask({
				mask: mask,
				clearIncomplete: false,
				showMaskOnHover: false
			});
		}
	});

	const handleOrderModal = (e) => {
		const parent = e.target.closest(".price-widget");
		const title = parent.querySelector(".price-widget__title").textContent;
		const subtitle = parent.querySelector(".price-widget__subtitle").textContent;
		const priceSum = parent.querySelector(".price-widget__sum").textContent;
		const priceLabel = parent.querySelector(".price-widget__label").innerHTML;

		const orderModal = document.querySelector(".order-box");
		const modalTitle = orderModal.querySelector(".order-box__head-title");
		const modalSubTitle = orderModal.querySelector(".order-box__head-subtitle");
		const modalPriceSum = orderModal.querySelector(".order-box__price-sum");
		const modalPriceLabel = orderModal.querySelector(".order-box__price-label");

		modalTitle.textContent = title;
		modalSubTitle.textContent = subtitle;
		modalPriceSum.textContent = priceSum;
		modalPriceLabel.innerHTML = priceLabel;
	};

	document.querySelectorAll(".price-widget__button").forEach((button) => {
		button.addEventListener("click", handleOrderModal);
	});
};

initScripts();

$(function () {
	const $block = $('.page-wrap');
	if (!$block.length) {
		return;
	}
	const $iframe = $block.find('iframe');
	const $table = $block.find('.rich-text table');

	$iframe.each(function () {
		$(this).wrap('<div class="responsive-frame"></div>');
	});

	$table.each(function() {
		if (!$(this).closest('td').length) {
			$(this).wrap("<div class='table-responsive'/>");
		}
	});
});

const introSlider = () => {
	const block = document.querySelector(".intro");
	if (!block) {
		return;
	}
	const slider = block.querySelector(".intro__list");
	const pagination = block.querySelector(".intro__list-pagination");

	const swiper = new Swiper(slider, {
		loop: true,
		slidesPerView: 1,
		effect: "fade",
		fadeEffect: {
			crossFade: true
		},
		autoplay: {
			delay: 2500,
			disableOnInteraction: false
		},
		pagination: {
			el: pagination,
			type: "progressbar"
		}
	});
};
introSlider();

const informerSlider = () => {
	const block = document.querySelector(".informer-box");
	if (!block) {
		return;
	}
	const slider = block.querySelector(".informer-box__list");
	const prevArrow = block.querySelector(".informer-box__arrow_prev");
	const nextArrow = block.querySelector(".informer-box__arrow_next");

	const swiper = new Swiper(slider, {
		breakpoints: {
			0: {
				autoHeight: true
			},
			768: {
				autoHeight: true
			},
			1024: {
				autoHeight: false
			}
		},
		slidesPerView: 1,
		effect: "fade",
		fadeEffect: {
			crossFade: true
		},
		navigation: {
			nextEl: nextArrow,
			prevEl: prevArrow
		}
	});
};
informerSlider();

const benefitsSlider = () => {
	const block = document.querySelector(".benefits-slider");
	if (!block) {
		return;
	}
	const slider = block.querySelector(".benefits-slider__list");
	const prevArrow = block.querySelector(".benefits-slider__arrow_prev");
	const nextArrow = block.querySelector(".benefits-slider__arrow_next");

	const swiper = new Swiper(slider, {
		breakpoints: {
			0: {
				spaceBetween: 20,
				slidesPerView: 1
			},
			768: {
				spaceBetween: 32,
				slidesPerView: 2
			}
		},
		navigation: {
			nextEl: nextArrow,
			prevEl: prevArrow
		}
	});
};
benefitsSlider();

const teamBox = () => {
	const block = document.querySelector(".team-box");
	if (!block) {
		return;
	}
	const slider = block.querySelector(".team-box__list");
	const prevArrow = block.querySelector(".team-box__arrow_prev");
	const nextArrow = block.querySelector(".team-box__arrow_next");

	const swiper = new Swiper(slider, {
		breakpoints: {
			0: {
				spaceBetween: 20,
				slidesPerView: 1
			},
			768: {
				spaceBetween: 32,
				slidesPerView: 3
			},
			1024: {
				spaceBetween: 32,
				slidesPerView: 4
			}
		},
		navigation: {
			nextEl: nextArrow,
			prevEl: prevArrow
		}
	});
};
teamBox();

const casesBox = () => {
	const block = document.querySelector(".cases-box");
	if (!block) {
		return;
	}
	const slider = block.querySelector(".cases-box__list");
	const prevArrow = block.querySelector(".cases-box__arrow_prev");
	const nextArrow = block.querySelector(".cases-box__arrow_next");

	const swiper = new Swiper(slider, {
		breakpoints: {
			0: {
				spaceBetween: 20,
				slidesPerView: 1
			},
			768: {
				spaceBetween: 32,
				slidesPerView: 2
			}
		},
		navigation: {
			nextEl: nextArrow,
			prevEl: prevArrow
		}
	});
};
casesBox();


// validation
$(function () {
	const $block = $('.validation-wrap');
	if (!$block) {
		return;
	}
	const $form = $block.find('form');
	const $this = $(this);

	$form.submit(function (e) {
		e.preventDefault();
		let validation = true;

		$(this).find('[required]').each(function () {
			let checked;
			if ($(this).val() === '') {
				validation = false;
				$(this).closest('.form-group').addClass('has-error');
			}
			if ($(this).is('[type="checkbox"]')) {
				checked = $(this).prop('checked');
				if (!checked) {
					validation = false;
					$(this).closest('.form-group').addClass('has-error');
				}
			}
		});

		$(this).find('input[type="email"]').each(function () {
			let emailReg;
			if ($(this).val() !== '') {
				emailReg = /^.+@.+\..+$/;
				if (!emailReg.test($(this).val())) {
					validation = false;
					$(this).closest('.form-group').addClass('has-error');
				}
			}
		});

		$(this).find('input[type="tel"]').each(function () {
			if (!$(this).inputmask('isComplete')) {
				validation = false;
				$(this).closest('.form-group').addClass('has-error');
			}
		});

		if (validation) {
			const $formWrap = $(this).closest('.validation-wrap');
			$formWrap.addClass('submitting');
			setTimeout(function () {
				$formWrap.removeClass('submitting').addClass('submitted');
			}, 1000);
			$(this).trigger('validation-submit', $(this));
		} else {
			$(this).trigger('validation-error', $(this));
		}
	});

	$(document).on('click focus', '.has-error input, .has-error textarea', function (e) {
		$(e.currentTarget).closest('.has-error').removeClass('has-error');
	});

	if ($this.find('.validation-wrap').hasClass('submitted')) {
		$this.find('form').get(0).reset();
	}

	$this.find('.has-error').removeClass('has-error');
	$this.find('.validation-wrap').removeClass('submitted');
});
